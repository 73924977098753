import { bodyItemType } from './bodyRenderer';
import * as servicesDataSource from '../data/services';
import { faqs, contact, testimonials } from '../data';

export const generateRandomNumberSequence = (rangeMax, sequenceLength) => {
  const numberSequence = [];

  while (numberSequence.length < sequenceLength) {
    const num = Math.floor(Math.random() * Math.floor(rangeMax));
    if (numberSequence.indexOf(num) === -1) {
      numberSequence.push(num);
    }
  }

  return numberSequence;
};

export const generateSummaryItem = (
  dataItem,
  thumbnailData,
  panelType,
  isService
) => {
  const data = dataItem[1];
  const firstParagraph = data.body.find(b => b.type === bodyItemType.TEXT);

  return {
    heading: data.heading,
    subHeading: data.subHeading,
    text: firstParagraph.content,
    type: panelType,
    imageUrl: thumbnailData[dataItem[0]],
    isService,
  };
};

export const generateServiceSummaryItems = (
  thumbnailData,
  type,
  excludeHeading
) => {
  const servicesData = { ...servicesDataSource };
  const servicesDataArrayPrime = Object.entries(servicesData);
  const servicesArray = [];
  servicesDataArrayPrime.forEach(s => {
    if (s[1].heading !== excludeHeading) {
      servicesArray.push(generateSummaryItem(s, thumbnailData, type, true));
    }
  });

  return servicesArray;
};

export const generateNonServiceSummaryItems = (
  thumbnailData,
  excludeHeading
) => {
  const summaryFaqs = {
    ...faqs,
    body: [...faqs.body],
  };
  summaryFaqs.body.unshift({
    type: bodyItemType.TEXT,
    content:
      'Have questions about personal training or Body Best Fitness? Check out the frequently asked questions (FAQs) section',
  });

  const summaryContact = {
    ...contact,
    body: [...contact.body],
  };

  summaryContact.body.unshift({
    type: bodyItemType.TEXT,
    content:
      'Get started today! Contact us to arrange a consultation or if you have any enquiries or just want to leave feedback.',
  });

  const dataArrayPrime = Object.entries({
    testimonials,
    faqs: summaryFaqs,
    contact: summaryContact,
  });

  const dataArray = [];

  dataArrayPrime.forEach(data => {
    if (data[1].heading !== excludeHeading) {
      dataArray.push(
        generateSummaryItem(
          data,
          thumbnailData,
          bodyItemType.SIDE_PANEL_ITEM,
          false
        )
      );
    }
  });

  return dataArray;
};

export const generateRandomSummaryItems = (thumbnailData, excludeHeading) => {
  const dataArrayPrime = generateServiceSummaryItems(
    thumbnailData,
    bodyItemType.SIDE_PANEL_ITEM,
    excludeHeading
  ).concat(generateNonServiceSummaryItems(thumbnailData, excludeHeading));

  const randomSequence = generateRandomNumberSequence(dataArrayPrime.length, 4);

  return randomSequence.map(idx => dataArrayPrime[idx]);
};
