import React from 'react';
import styled from 'styled-components';
import { white, blue } from './styles/colours';
import { diagonalLines } from './styles/background-images';
import { mq } from './styles/media-queries';

const ContactInfoContainer = styled.div`
  padding: 32px;
  border-radius: 12px;
  background-image: url("${diagonalLines}");
  box-shadow: inset -1px 1px 8px -1px rgba(0,0,0,0.75);
`;

const ContactRow = styled.div`
  display: flex;
  margin-bottom: 32px;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 1);

  &:last-of-type {
    margin-bottom: 0;
  }

  ${mq.xsmall} {
    display: block;
  }
`;

const ContactLabel = styled.label`
  font-weight: bold;
  color: ${white};
  width: 200px;
  ${mq.xsmall} {
    display: block;
    margin-bottom: 0.5rem;
  }
`;

const ContactInfo = styled.span`
  display: inline-block;

  a {
    color: ${blue};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ContactUs = () => (
  <ContactInfoContainer>
    <ContactRow>
      <ContactLabel>Email: </ContactLabel>
      <ContactInfo>
        <a href="mailto:b.fit@hotmail.co.uk" target="blank">
          b.fit@hotmail.co.uk
        </a>
      </ContactInfo>
    </ContactRow>
    <ContactRow>
      <ContactLabel>Phone: </ContactLabel>
      <ContactInfo>+44 7985 436 380</ContactInfo>
    </ContactRow>
    <ContactRow>
      <ContactLabel>Social Media: </ContactLabel>
      <ContactInfo>
        <a href="http://linkedin.com/in/best-agofure-b95a3227" target="blank">
          LinkedIn
        </a>
      </ContactInfo>
    </ContactRow>
  </ContactInfoContainer>
);
