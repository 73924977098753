import styled from 'styled-components';
import { white, lightGrey } from '../styles/colours';
import { mainHeadingShadow } from '../styles/text';

export const SpecialtyHeading = styled.h2`
  border-top: solid ${lightGrey} 2px;
  border-bottom: dashed ${lightGrey} 3px;
  font-size: 1.25rem;
  font-style: italic;
  font-weight: normal;
  padding: 1rem 0;
  margin: 2rem 0;
  text-shadow: ${mainHeadingShadow};
  color: ${white};
`;
