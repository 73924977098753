import React from 'react';
import styled from 'styled-components';
import { white } from './styles/colours';
import { DottedDivider, SolidDivider } from '.';

const TestimonialContainer = styled.div`
  margin-bottom: 2rem;
`;

const TestimonialsContainer = styled.div`
  ${TestimonialContainer}:last-of-type {
    margin-bottom: 0;

    ${SolidDivider} {
      display: none;
    }
  }
`;

const Author = styled.div`
  text-align: right;
  color: ${white};
  font-weight: bold;
  font-style: italic;
  padding-right: 1rem;
  font-size: 1.2rem;
`;

export const Testimonial = ({ author, text }) => {
  return (
    <TestimonialContainer>
      <p>&ldquo;{text}&rdquo;</p>
      <DottedDivider margin="0.75rem" />
      <Author>~ {author}</Author>
      <SolidDivider margin="0.75rem" className="testimonial" />
    </TestimonialContainer>
  );
};

export const Testimonials = ({ testimonials }) => (
  <TestimonialsContainer>
    {testimonials.map(t => (
      <Testimonial {...t} />
    ))}
  </TestimonialsContainer>
);
