export const servicesMenu = [
  {
    text: 'BODY TONING',
    href: '/services/body-toning',
  },
  {
    text: 'CORE AND STABILITY TRAINING',
    href: '/services/core-and-stability-training',
  },
  {
    text: 'DUMBBELLS TO WEDDING BELLS',
    href: '/services/dumbbells-to-wedding-bells',
  },
  {
    text: 'FIGHTING FIT BOXING',
    href: '/services/fighting-fit-boxing',
  },
  {
    text: 'HOME FITNESS TRAINING',
    href: '/services/home-fitness-training',
  },
  {
    text: 'SHED POUNDS',
    href: '/services/shed-pounds',
  },
];
