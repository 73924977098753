import styled from 'styled-components';
import { white } from './colours';

export const headingShadow = '2px 2px 2px rgba(0, 0, 0, 1)';
export const mainHeadingShadow = '4px 4px 3px rgba(0,0,0,1)';

export const textShadow = '2px 2px 1px rgba(0,0,0,1)';

export const BestQuote = styled.p`
  font-size: 1.25rem;
  font-style: italic;
  font-weight: bold;
  color: ${white};
`;

export const Instruction = styled.p`
  color: ${white};
`;
