import React from 'react';
import styled from 'styled-components';
import { blue, white } from './styles/colours';
import { Link, MoreLink } from './styles/link';
import { DottedDivider, SolidDivider } from '.';
import { summarizeText } from '../helpers/summarizeText';
import { mq } from './styles/media-queries';

const ServicePanelContainer = styled.div`
  width: 50%;
  display: inline-block;
  margin-bottom: 1rem;
  vertical-align: top;

  &:nth-of-type(odd) {
    padding-right: 0.75rem;
  }
  &:nth-of-type(even) {
    padding-left: 0.75rem;
  }

  ${mq.small} {
    display: block;
    width: auto;

    &:nth-of-type(odd) {
      padding-right: 0;
    }
    &:nth-of-type(even) {
      padding-left: 0;
    }
  }
`;

const ServicePanelHeading = styled.h3`
  color: ${blue};
  font-style: italic;
  font-size: 1.5rem;
  margin: 0 0 0.5rem 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${mq.small} {
    white-space: normal;
    overflow: initial;
    text-overflow: initial;
  }
`;

const ServicePanerlSubHeading = styled.h4`
  color: ${white};
  margin: 0;
  font-size: 1.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${mq.large} {
    font-size: 1rem;
  }

  ${mq.small} {
    white-space: normal;
    overflow: initial;
    text-overflow: initial;
  }
`;

const ServicePanelContent = styled.div`
  padding-left: 146px;
  padding-bottom: 1rem;
  position: relative;
  min-height: 130px;

  ${mq.large} {
    padding-left: 96px;
  }
  ${mq.medium} {
    font-size: 1rem;
  }
  ${mq.small} {
    min-height: 100px;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;

    ${mq.large} {
      width: 80px;
      height: 80px;
    }
  }
`;

const ServicePanelMoreLink = styled(MoreLink)`
  position: absolute;
  bottom: 0;
  right: 0;

  ${mq.small} {
    font-size: 15px;
  }
`;

export const ServicePanel = props => {
  const { heading, subHeading, imageUrl, text } = props;
  const hyphenatedName = heading.toLowerCase().replace(/ /g, '-');
  const url = `/services/${hyphenatedName}`;

  return (
    <ServicePanelContainer>
      <ServicePanelHeading title={heading}>
        <Link to={url}>{heading}</Link>
      </ServicePanelHeading>
      <ServicePanerlSubHeading title={subHeading}>
        {subHeading}
      </ServicePanerlSubHeading>
      <DottedDivider />
      <ServicePanelContent>
        <Link to={url}>
          <img src={imageUrl} alt={heading} />
        </Link>
        <p>{summarizeText(text, 180)}</p>
        <ServicePanelMoreLink to={url}>Find Out More</ServicePanelMoreLink>
      </ServicePanelContent>
      <SolidDivider />
    </ServicePanelContainer>
  );
};
