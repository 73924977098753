import styled from 'styled-components';
import { Link } from 'gatsby';
import { linearGradient, diagonalLines } from '../styles/background-images';
import { blue, lightBlue, white, pink, lightGrey } from '../styles/colours';
import { textShadow } from '../styles/text';
import { servicesMenu } from './data';
import { mq } from '../styles/media-queries';

const linkHeight = '46px';

export const SiteHeader = styled.header`
  ${mq.small} {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10;
  }
`;

export const SiteHeaderContent = styled.div`
  padding: 24px;
  background-color: #000;

  ${mq.medium} {
    padding-top: 18px;
  }
  ${mq.small} {
    padding: 1rem;

    svg.logo {
      width: 250px;
    }
  }

  ${mq.xsmall} {
    svg.logo {
      width: 210px;
    }
  }
`;

export const MainNavigation = styled.nav`
  height: ${linkHeight};
  line-height: ${linkHeight};
  background: ${linearGradient};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.8);
  position: relative;
  z-index: 2;
  font-size: 18px;

  ${mq.small} {
    height: 0;
  }
`;

export const MainNavigationPattern = styled.div`
  background-image: url("${diagonalLines}");
  height: inherit;
`;

export const MainNavSubNavMenu = styled.ul`
  background-color: rgba(0, 0, 0, 0.75);
  position: absolute;
  border: solid 1px #444444;
  padding: 8px;
  overflow: hidden;
  margin: -1px 0 0 8px;
  box-sizing: border-box;

  > li {
    list-style: none;
    border: solid 1px transparent;
    float: none;
    margin: 0;
    width: 346px;

    &:hover {
      border-color: #555;
      background: ${linearGradient};
    }

    ${mq.small} {
      border: none;
      width: auto;

      &:hover {
        background: none;
      }
    }
  }

  ${mq.small} {
    position: relative;
    background-color: transparent;
    margin: 0;
    padding: 0 0 1rem 1.5rem;
    border: none;
  }
`;

export const MainNavSubNavMenuLink = styled(Link)`
  display: block;
  padding: 1px 24px 0 28px;
  box-sizing: border-box;
  line-height: ${linkHeight};
  height: ${linkHeight};
  background-image: url("${diagonalLines}");
  background-size: 0;
  text-decoration: none;
  color: ${blue};
  position: relative;
  text-shadow: ${textShadow};

  ${mq.small} {
    padding: 14px 24px 14px 28px;
    line-height: 1.5;
    height: auto;
  }

  &:hover {
    color: ${white};
    background-size: initial;

    ${mq.small} {
      background-size: 0;
    }
  }

  &::before {
    width: 8px;
    height: 8px;
    background-color: ${pink};
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    margin-top: 1px;
    box-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 1);

    ${mq.small} {
      top: 25px;
    }
  }
`;

export const MainNavLinks = styled.ul`
  margin: 0 auto;
  padding: 0 1rem;
  display: flow-root;

  ${mq.small} {
    display: none;
    position: absolute;
    right: 0;
    max-width: 480px;
    width: 100%;
    height: calc(100vh - 109px);
    background-color: rgba(0, 0, 0, 0.8);
    padding: 1rem 1rem 2rem 1rem;
    overflow: auto;
    transition: right 2s linear 0.1s;
  }
  ${mq.xsmall} {
    padding: 1rem 0 2rem 0;
  }

  > li {
    float: left;
    list-style: none;
    margin: 0;

    ${mq.small} {
      float: none;
    }

    ${MainNavSubNavMenu} {
      width: 0;
      height: 0;
      opacity: 0;
      transition: opacity 0ms linear 0.2s,
        height 0.2s cubic-bezier(0.48, -0.01, 0.985, 0.65),
        width 0.2s cubic-bezier(0.48, -0.01, 0.985, 0.65);

      ${mq.small} {
        width: auto;
        height: auto;
        opacity: 1;
        transition: none;
      }
    }
  }

  li:hover {
    ${MainNavSubNavMenu} {
      width: 364px;
      height: ${servicesMenu.length * 48 + 16 + 2}px;
      opacity: 1;
      transition: opacity 0s linear 0s,
        height 0.2s cubic-bezier(0.48, -0.01, 0.985, 0.65),
        width 0.2s cubic-bezier(0.48, -0.01, 0.985, 0.65);

      ${mq.small} {
        width: auto;
        height: auto;
        transition: none;
      }
    }
  }
`;

export const SubMenuIcon = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${blue};
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-block;
  box-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 1);
  margin-left: 3px;
  position: relative;
  top: 1px;
`;

export const SubMenuIconArrow = styled.div`
  width: 10px;
  height: 5px;
  margin: 0 auto;
  background-color: ${white};
  clip-path: polygon(0 0, 100% 0, 50% 100%, 0 0);
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;

export const MainNavLink = styled(Link)`
  color: ${blue};
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
  padding: 0 16px;
  text-shadow: ${textShadow};

  &:hover {
    color: #fff;
  }

  ${mq.small} {
    display: block;
    padding: 0 16px;
    border: solid 1px transparent;
    text-shadow: ${textShadow};
  }
`;

export const HamburgerBar = styled.span`
  display: block;
  height: 4px;
  background-color: ${lightGrey};
  margin: 0.3rem 0.75rem;
`;

export const HamburgerLabel = styled.label`
  display: none;
  width: 60px;
  height: 55px;
  border: solid 1px ${lightGrey};
  position: absolute;
  right: 1rem;
  bottom: 29px;
  z-index: 1;
  cursor: pointer;

  ${mq.small} {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const HamburgerCheckbox = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;

  &:checked ~ ${HamburgerLabel} {
    border-color: ${lightBlue};

    ${HamburgerBar} {
      background-color: ${lightBlue};
    }
  }

  ${mq.small} {
    &:checked ~ ${MainNavLinks} {
      display: block;
    }
  }
`;
