import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import { blue, pink, lightBlue } from './colours';

export const Link = styled(GatsbyLink)`
  color: ${blue};
  text-decoration: none;

  &:hover {
    color: ${lightBlue};
  }
`;

export const MoreLink = styled(Link)`
  padding-left: 28px;

  &::before {
    width: 8px;
    height: 8px;
    background-color: ${pink};
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    margin-top: 1px;
  }
`;
