export const generateThumbnailData = thumbnails => {
  const thumbnailData = {};
  thumbnails.nodes.forEach(tn => {
    const nameArray = tn.name.split('-');
    nameArray.forEach((nm, idx) => {
      if (idx > 0) {
        nameArray[idx] = `${nm.substr(0, 1).toUpperCase()}${nm.substr(1)}`;
      }
    });

    thumbnailData[nameArray.join('')] = tn.publicURL;
  });
  thumbnailData.faqs = thumbnailData.FAQs;
  thumbnailData.testimonials = thumbnailData.clientTestimonials;

  return thumbnailData;
};
