import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { blue, lightBlue } from './styles/colours';
import { DottedDivider } from '.';
import { SolidDivider } from './solid-divider';
import { mq } from './styles/media-queries';

const FaqsList = styled.ul`
  margin: 0;
`;

const FaqItem = styled.li`
  list-style: none;

  ul {
    margin-left: 1.25rem;
  }

  li {
    list-style: square;
    margin-bottom: 0.5rem;
  }

  ${DottedDivider} {
    margin: 1rem 0;
  }
`;

const FaqLabel = styled.span`
  margin-right: 0.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  font-style: italic;
  display: inline-block;
`;

const Question = styled.h3`
  color: ${p => (p.isVisible ? lightBlue : blue)};
  font-style: italic;
  font-size: 1.5rem;
  margin: 0 0 0.25rem 0;
  display: flex;
  cursor: pointer;

  ${mq.medium} {
    font-size: 1.25rem;
  }
`;

const Answer = styled.div`
  overflow: hidden;
  margin-bottom: 2rem;
  padding-bottom: 0;
`;

const AnswerInner = styled.div`
  display: flex;
`;

const AnswerContent = styled.div`
  margin-top: 0.4rem;
`;

export const FAQ = ({ q, a, isVisible, onClick, index }) => {
  const [initialHeight, setInitialHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    if (initialHeight === 0) {
      setInitialHeight(ref.current.offsetHeight);
    }
  }, [ref.current]);

  const onQuestionClick = () => {
    onClick(index);
  };

  return (
    <FaqItem>
      <Question onClick={onQuestionClick} isVisible={isVisible}>
        <FaqLabel>Q: </FaqLabel>
        &ldquo;{q}&rdquo;
      </Question>
      <DottedDivider />
      <Answer
        ref={ref}
        style={{
          transition: initialHeight
            ? 'height 0.25s ease-out, opacity 0.5s'
            : 'none',
          height: initialHeight
            ? isVisible
              ? `${initialHeight + 24}px`
              : 0
            : undefined,
          opacity: isVisible ? 1 : 0,
        }}
      >
        <AnswerInner>
          <FaqLabel>A: </FaqLabel>
          <AnswerContent>{a}</AnswerContent>
        </AnswerInner>
        <SolidDivider />
      </Answer>
    </FaqItem>
  );
};

export const FAQs = ({ faqs }) => {
  const [currentIdx, setCurrentIdx] = useState(-1);

  const onClick = idx => {
    if (idx === currentIdx) {
      setCurrentIdx(-1);
      return;
    }
    setCurrentIdx(idx);
  };

  return (
    <FaqsList>
      {faqs.map((f, idx) => (
        <FAQ
          key={f.q}
          {...f}
          isVisible={idx === currentIdx}
          onClick={onClick}
          index={idx}
        />
      ))}
    </FaqsList>
  );
};
