import styled from 'styled-components';
import { mq } from './media-queries';

export const SpecialtyList = styled.ul`
  display: flow-root;

  li {
    float: left;
    width: 50%;

    ${mq.xsmall} {
      float: none;
      width: auto;
    }
  }
`;

export const ServiceList = styled.ul``;
