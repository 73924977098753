import React from 'react';
import styled from 'styled-components';
import { Icon } from './icons';
import { mq } from './styles/media-queries';

const ContactInfoContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 24px;

  ${mq.medium} {
    position: relative;
    top: auto;
    right: auto;
    margin-bottom: 1rem;
  }

  ${mq.small} {
    display: none;
  }
`;

const ContactInfoItem = styled.span`
  margin-right: 1.5rem;

  ${mq.medium} {
    margin-right: 2rem;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

const iconProps = {
  width: '24px',
  margin: '0 8px 0 0',
};

export const ContactInfo = () => (
  <ContactInfoContainer>
    <ContactInfoItem>
      <Icon iconName="phone" {...iconProps} />
      +44 7985 436 380
    </ContactInfoItem>
    <ContactInfoItem>
      <Icon iconName="linkedin" {...iconProps} />
      <a href="http://linkedin.com/in/best-agofure-b95a3227" target="blank">
        LinkedIn
      </a>
    </ContactInfoItem>
    <ContactInfoItem>
      <Icon iconName="email" {...iconProps} />
      <a href="mailto:b.fit@hotmail.co.uk">b.fit@hotmail.co.uk</a>
    </ContactInfoItem>
  </ContactInfoContainer>
);
