import React from 'react';
import { bodyItemType } from '../helpers/bodyRenderer';
import { Link } from '../components/styles/link';

export const faqs = {
  heading: 'FAQs',
  body: [
    {
      type: bodyItemType.HTML,
      content: () => (
        <p>
          If you can't find the information you're looking for in the frequently
          asked questions below, then please feel free to{' '}
          <Link to="/contact">send us an enquiry</Link>.
        </p>
      ),
    },
    {
      type: bodyItemType.INSTRUCTION,
      content: 'Click on a question to view the answer:',
    },
    {
      type: bodyItemType.DIVIDER,
    },
    {
      type: bodyItemType.FAQ,
      faqs: [
        {
          q: 'Is personal training for everyone?',
          a: (
            <p>
              Yes, no matter the sex, age or fitness level everyone will benefit
              from working with us.
            </p>
          ),
        },
        {
          q: 'Why choose Body Best Fitness Personal Training?',
          a: (
            <>
              <p>Because with our advice and training you will:</p>
              <ul>
                <li>lose weight</li>
                <li>gain muscle tone</li>
                <li>gain strength</li>
                <li>experience increased energy levels</li>
                <li>get fit</li>
                <li>experience boosted levels of confidence</li>
                <li>reduce stress</li>
                <li>improve core stability</li>
              </ul>
              <p>
                With our encouragement, guidance and motivation you will achieve
                these targets!
              </p>
            </>
          ),
        },
        {
          q:
            'What guarantee would I have that working out with Body Best Fitness will get me the results I want?',
          a: (
            <p>
              The only guarantee you have is the amount of commitment you give
              yourself. The more you put into it, the more you're going to get
              out of it.
            </p>
          ),
        },
        {
          q: "What's the first thing I'll be expected to do?",
          a: (
            <p>
              We will ask you to fill out a PARQ (Physical Activity Readiness
              Questionnaire), a form that asks you questions about your medical
              history. This form serves as a safety precaution for both yourself
              and us. If you have had any serious health problems in the past,
              we will advise you to seek your doctor's approval before training.
              It also helps us to assess your physical capabilities. Then we
              will write down your goals, why they are important to you, how we
              go about reaching them and how much you are committed to reaching
              them.
            </p>
          ),
        },
        {
          q: 'What do I need to bring to a training session?',
          a: (
            <p>
              All you need to bring to the session is yourself wearing training
              gear and comfortable trainers and we provide the rest e.g. sweat
              towel, training tools, water, and a banana for after the workout.
            </p>
          ),
        },
        {
          q: 'How long do the sessions last?',
          a: (
            <p>
              All training sessions last 1 hour, this ensures that we train your
              body to it's optimum level without risking the counter-productive
              release of excessive stress hormones, which inevitably accompany
              training periods any longer than 1 hour.
            </p>
          ),
        },
        {
          q: 'Will I have to give up drinking and junk food to lose weight?',
          a: (
            <p>
              Not entirely! A little of what we like is fine, however, you
              cannot out train a bad diet pure and simple. Healthy eating is a
              must if you want to change your body. Remember, we are what we eat
              and the results that you get will be in direct proportion to the
              effort and discipline you put forth.
            </p>
          ),
        },
        {
          q: 'How do I control my sugar craving after a meal?',
          a: (
            <p>
              One of the best ways to deal with sugar cravings after a meal is
              to replace a typical desert with a natural and sweet alternative.
              A great choice is to freeze fruit like grapes, strawberries, or
              cherries and enjoy them after your meal. Fruit contains the
              natural sugar fructose which is relatively low on the glycemic
              index and also has less calories and fat than a typical desert. If
              you don't like frozen fruit, then just use a basic fruit dish with
              several different types like canaloupe, watermelon, oranges and
              apples.
            </p>
          ),
        },
        {
          q: 'What happens during my free intitial consultation?',
          a: (
            <p>
              Your free initial consultation is a relaxed and informal meeting
              that allows us to explore your health and fitness goals in greater
              detail. This consultation is obligation free. If by the end of the
              consultation you would like to go ahead and begin personal
              training we can book your first training session and agree upon a
              start date. But no matter what you decide, we hope the meeting
              will leave you feeling even more motivated to take action towards
              a healthier lifestyle.
            </p>
          ),
        },
        {
          q: 'Do women have to worry about developing big bulky muscles?',
          a: (
            <p>
              I know quite a few men who have been trying to develop big bulky
              muscles for years and still haven't achieved their goal. So to
              answer your question, NO. The majority of women do not have the
              level of testosterone needed to achieve such muscular development.
              If your goal is to get a firm feminine physique that's exactly how
              we will train you.
            </p>
          ),
        },
      ],
    },
  ],
};
