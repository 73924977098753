import React from 'react';
import styled from 'styled-components';

const icons = {
  phone: (
    <svg viewBox="0 0 64 64">
      <g id="XMLID_107_">
        <circle fill="#3CA9E7" cx="32" cy="32" r="31" />
        <g>
          <path
            d="M32.358,16H16.642C15.183,16,14,17.183,14,18.643v28.715C14,48.816,15.183,50,16.642,50h15.716
			C33.817,50,35,48.816,35,47.357V18.643C35,17.183,33.817,16,32.358,16z M24.454,49.293c-1.381,0-2.5-1.12-2.5-2.502
			c0-1.381,1.119-2.499,2.5-2.499c1.38,0,2.5,1.118,2.5,2.499C26.954,48.173,25.834,49.293,24.454,49.293z M32,43.449
			C32,43.753,31.753,44,31.448,44H17.552C17.247,44,17,43.753,17,43.449V19.551C17,19.247,17.247,19,17.552,19h13.896
			C31.753,19,32,19.247,32,19.551V43.449z"
          />
          <path
            d="M48.867,19.536c-0.55-0.55-1.439-0.55-1.987,0c-0.55,0.549-0.55,1.438,0,1.987c5.747,5.749,5.747,15.101,0,20.85
			c-0.55,0.549-0.55,1.438,0,1.988c0.274,0.274,0.634,0.411,0.993,0.411s0.719-0.137,0.994-0.411
			C55.711,37.517,55.711,26.38,48.867,19.536z"
          />
          <path
            d="M44.768,23.636c-0.549-0.549-1.438-0.549-1.987,0c-0.549,0.548-0.549,1.438,0,1.987c3.487,3.487,3.487,9.163,0,12.65
			c-0.549,0.549-0.549,1.439,0,1.987c0.275,0.274,0.635,0.411,0.994,0.411c0.36,0,0.719-0.137,0.993-0.411
			C49.352,35.678,49.352,28.219,44.768,23.636z"
          />
          <path
            d="M38.642,27.775c-0.55,0.548-0.55,1.438,0,1.988c1.203,1.205,1.203,3.166,0,4.372c-0.55,0.549-0.55,1.438,0,1.986
			c0.273,0.275,0.634,0.412,0.993,0.412s0.719-0.137,0.993-0.412c2.301-2.301,2.301-6.045,0-8.346
			C40.079,27.226,39.189,27.226,38.642,27.775z"
          />
        </g>
      </g>
    </svg>
  ),
  email: (
    <svg viewBox="0 0 64 64">
      <circle fill="#3CA9E7" cx="32" cy="32" r="31" />
      <g>
        <path
          d="M50.762,17.895H13.249c-0.861,0-1.578,0.63-1.719,1.45c-0.047,0.201-0.022,27.01-0.022,27.01
		c0,0.96,0.784,1.751,1.742,1.751H50.68c0.955,0,1.734-0.704,1.82-1.667V19.636C52.514,18.674,51.722,17.895,50.762,17.895z
		 M45.349,21.377l-13.38,9.578l-13.379-9.578H45.349z M14.906,44.712V22.955l16.104,11.483c0.657,0.53,1.441,0.372,1.922,0
		L49.038,22.9v21.812H14.906L14.906,44.712z"
        />
      </g>
    </svg>
  ),
  linkedin: (
    <svg viewBox="0 0 64 64">
      <circle fill="#3CA9E7" cx="32" cy="32" r="31" />
      <g id="XMLID_801_">
        <path
          id="XMLID_802_"
          d="M24.713,25.471h-6.222c-0.272,0-0.499,0.224-0.499,0.5v20c0,0.271,0.227,0.491,0.499,0.491h6.222
		c0.279,0,0.499-0.22,0.499-0.491v-20C25.212,25.694,24.992,25.471,24.713,25.471z"
        />
        <path
          id="XMLID_803_"
          d="M21.609,15.538c-2.271,0-4.109,1.833-4.109,4.102c0,2.256,1.839,4.103,4.109,4.103
		c2.256,0,4.103-1.847,4.103-4.103C25.712,17.372,23.866,15.538,21.609,15.538z"
        />
        <path
          id="XMLID_804_"
          d="M40.553,24.977c-2.512,0-4.359,1.074-5.479,2.292v-1.297c0-0.277-0.213-0.5-0.493-0.5h-5.963
		c-0.273,0-0.5,0.224-0.5,0.5v20c0,0.271,0.227,0.491,0.5,0.491h6.214c0.271,0,0.491-0.22,0.491-0.491v-9.9
		c0-3.33,0.902-4.625,3.231-4.625c2.536,0,2.727,2.083,2.727,4.807v9.719c0,0.271,0.235,0.491,0.514,0.491H48
		c0.273,0,0.5-0.22,0.5-0.491V35.003C48.5,30.04,47.548,24.977,40.553,24.977z"
        />
      </g>
    </svg>
  ),
  send: (
    <svg viewBox="0 0 64 64">
      <g id="Arrow_26">
        <path
          fill="#3CA9E7"
          d="M32,1.169C17.052,1.169,4.577,12,1.651,26H27V13.86L51.791,32L27,50.141V38H1.651
		C4.578,52,17.052,62.83,32,62.83c17.12,0,31-13.71,31-30.831S49.12,1.169,32,1.169z"
        />
      </g>
    </svg>
  ),
};

const StyledIcon = styled.span`
  width: ${p => p.width || null};
  height: ${p => p.height || null};
  display: inline-block;
  vertical-align: middle;
  margin: ${p => p.margin || null};
`;

export const Icon = ({ iconName, ...rest }) => (
  <StyledIcon {...rest}>{icons[iconName]}</StyledIcon>
);
