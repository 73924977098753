/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import { Header } from './header';
import './layout.css';
import { mq } from './styles/media-queries';

const SiteContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  background-color: #222;
  position: relative;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.65);

  ${mq.small} {
    padding-top: 110px;
  }
`;

const SiteFooter = styled.footer`
  color: #c6c6c6;
  font-size: 14px;
  text-align: right;
  padding: 8px 16px;
  max-width: 1440px;
  margin: 0 auto;
`;

export const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <SiteContainer>
        <Header siteTitle={data.site.siteMetadata.title} />
        <main>{children}</main>
      </SiteContainer>
      <SiteFooter>
        &copy; Body Best Fitness {new Date().getFullYear()}
      </SiteFooter>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
