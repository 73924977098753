import { bodyItemType } from '../helpers/bodyRenderer';

export const homeFitnessTraining = {
  heading: 'HOME FITNESS TRAINING',
  subHeading: 'WE COME TO YOU IN PERSON OR VIA VIDEO',
  body: [
    {
      type: bodyItemType.TEXT,
      content:
        'No gym, no problem, you can now train from the comfort of your home via Skype, FaceTime, video call.Let me help you produce exceptional results. Workout with me from anywhere with my Live Online personal training sessions. Workout from home or from the gym using your mobile! Online personal training sessions gives you the same interaction that you would have if I was personally training you at the gym. If you have equipment that you can incorporate into your training, that’s great but don’t worry if you don’t because most of my workouts will be tailored to suit you. No more excuses on why you can’t make it to the gym.',
    },
    {
      type: bodyItemType.TEXT,
      content:
        'You can get the same outstanding results working out in the privacy and comfort of your home or in a park, as you can by training in a gym. Our proven in-home and park fitness techniques include the use of swiss ball, free weights, kettlebells, ViPR, skipping rope, exercise step, resistance bands, medicine ball, boxing gloves and training mats. We have also designed a program that requires no equipment at all.',
    },
    {
      type: bodyItemType.LIST,
      content: {
        heading:
          'THE BODY BEST FITNESS ONLINE, IN-HOME AND PARK TRAINING PROGRAM OFFERS:',
        imageName: '',
        items: [
          'One-on-one personal training session with a professional Body Best Fitness trainer',
          'Full lifestyle, health and fitness assessment',
          'A fitness regimen tailored to your level of fitness and goals',
          '"Off day" programs, specially designed for days between personal training sessions',
          'Travel training programs',
        ],
      },
    },
  ],
};

export const bodyToning = {
  heading: 'BODY TONING',
  subHeading: `GET THE BODY YOU'VE BEEN DREAMING OF`,
  body: [
    {
      type: bodyItemType.TEXT,
      content:
        'The program that re-shapes your body through weight training. There are so many benefits to body toning the Body Best Fitness way - from burning unwanted calories, making you feel more toned, to getting you stronger and giving you confidence to deal with your daily activities.',
    },
    {
      type: bodyItemType.TEXT,
      content:
        'For the guys out there, if you want to get those legs, back, pecs and biceps looking good in shorts and a vest then Body Best Fitness are the trainers that will enable you to achieve your goal.',
    },
    {
      type: bodyItemType.TEXT,
      content:
        "Ladies, adding weights to your workout will sort out those wobbly bits - legs, bottom, arms and tummy - making you look 10 times better in a dress. I know what you're thinking, will doing weights make you bulky? No, absolutely not! There is no way that doing weights will get you bulky, this is down to the simple fact that you don't have high testosterone levels like men.",
    },
  ],
};

export const coreAndStabilityTraining = {
  heading: 'CORE AND STABILITY TRAINING',
  subHeading: 'USING SWISS BALL AND BOSU',
  body: [
    {
      type: bodyItemType.H2,
      content: 'Core Training',
    },
    {
      type: bodyItemType.TEXT,
      content:
        "We have all heard about core training, whether you're with a physiotherapist or in a Pilates class, it is fundamental to having a stronger back. It is especially relevant if you sit at a desk all day - sooner or later you will have back problems unless you take action. At Body Best Fitness we aim to prevent back problems by training the Core, concentrating primarily on the centre of the body which is the origin of all movement. The Core includes the abdominal area, the glute muscle (buttocks) as well as the movement of the spine. When these muscular and skeletal groups are strengthened it serves to improve overall exercise as well as neuro-muscular ability. Strengthening core muscles is essential to alleviating back pain and improving posture.",
    },
    {
      type: bodyItemType.H2,
      content: 'Stability Training',
    },
    {
      type: bodyItemType.TEXT,
      content:
        "In the past, stability has been overlooked as an imperative part of fitness. Only in recent years has its importance been acknowledged as an essential component for all ages and body types. Since stability gets worse with age, it's never too soon to incorporate it into an exercise regime. Improving one’s balance will enrich the nervous system, improving overall perception and exercise.",
    },
  ],
};

export const dumbbellsToWeddingBells = {
  heading: 'DUMBBELLS TO WEDDING BELLS',
  subHeading: 'GET IN SHAPE FOR YOUR BIG DAY',
  body: [
    {
      type: bodyItemType.H2,
      content:
        "Help! I'm getting married soon and want to look great on my big day.",
    },
    {
      type: bodyItemType.TEXT,
      content:
        'You can absolutely look great on your big day! By working out for just a few hours a week and being more conscious of the foods you eat, the pounds will dissolve. Think about how much time you spend watching TV or shopping, it all adds up. Setting aside time to improve your body is worth it and will completely change your life. With our help and encouragement, you will not only look great on your wedding day, but feel strong and confident as well.',
    },
    {
      type: bodyItemType.H2,
      content: 'How much time do I need?',
    },
    {
      type: bodyItemType.TEXT,
      content:
        'That really and truly depends on how far you are from your goals - ideally you should lose no more than 2-3 lbs per week. So if your goal is to shed 15 lbs then you would need 5 weeks minimum. The more time you have the better shape we can get you into.',
    },
    {
      type: bodyItemType.H2,
      content: 'What will we be doing?',
    },
    {
      type: bodyItemType.TEXT,
      content:
        'In the first session we will talk about where you are, where you want to get to and do a thorough fitness evaluation to determine how we will get there.',
    },
    {
      type: bodyItemType.TEXT,
      content:
        'One of the most important things when walking down the aisle is elegance and this is determined in large part by posture. How is your posture? No matter how "in shape" you think you are, if your posture is bad you will not see it. Have you ever seen a model with bad posture? Good posture will not only give the appearance of being pounds lighter, but will make your walk down the aisle perfection.',
    },
  ],
};

export const fightingFitBoxing = {
  heading: 'FIGHTING FIT BOXING',
  subHeading: 'GREAT FUN CALORIE BURNER',
  body: [
    {
      type: bodyItemType.TEXT,
      content:
        'Get in the best shape of your life, transform your life and empower yourself in our Fighting Fit Boxing session. The session will help you lose those unwanted pounds, transform your physique and build confidence. Our Fighting Fit Boxing session is for anyone who wants to get in shape or is looking for the ultimate workout.',
    },
    {
      type: bodyItemType.TEXT,
      content:
        "For optimum fitness you must have low body fat, cardiovascular endurance, muscle tone and flexibility. With our help you can attain all four of these elements. A Fighting Fit Boxing session focuses heavily on a good cardio workout which includes aerobic and anaerobic activities. We will teach you the technique of hitting the pad with kicks, knees and punches. You'll also be doing body weight resistance exercises and abdominal work. This type of workout will test your endurance, work your core muscles, burn calories, tone up your arms, legs and bottom like you wouldn’t believe.",
    },
  ],
};

export const shedPounds = {
  heading: 'SHED POUNDS',
  subHeading: 'THE RIGHT WAY TO DO IT',
  body: [
    {
      type: bodyItemType.TEXT,
      content:
        "Shedding pounds can be one of the hardest challenges you'll ever have to face, especially if you've tried it one too many times and didn't achieve your goal. You would have asked yourself \"What am I doing wrong? Why isn't this weight coming off?\" These are common questions we are asked all the time. At Body Best Fitness we step back and look at the whole picture to see what works for you and what doesn't. We've realised through years of experience that there is no single program that works for everyone. We are all unique individuals and we train you as such. Believe in us because with our training and guidance you'll quickly see dramatic weight loss results, making you look good and feel good. We deliver results!",
    },
  ],
};
