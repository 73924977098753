import React from 'react';
import { SpecialtyHeading, Instruction } from '../components';
import { H2 } from '../components/headings';
import { Divider } from '../components/divider';
import { ServicePanel } from '../components/service-panel';
import { Testimonials } from '../components/testimonials';
import { FAQs } from '../components/faqs';
import { ContactUs } from '../components/contact-us';
import { SidePanelItem } from '../components/side-panel-item';

export const bodyItemType = {
  TEXT: 'text',
  LIST: 'list',
  H2: 'h2',
  SERVICE_PANEL: 'service-panel',
  TESTIMONIALS: 'testimonials',
  DIVIDER: 'divider',
  HTML: 'html',
  INSTRUCTION: 'instruction',
  FAQ: 'faq',
  CONTACT_FORM: 'contact-form',
  CONTACT_US: 'contact-us',
  SIDE_PANEL_ITEM: 'side-panel-item',
};

export const bodyRenderer = (bodyItem, key) => {
  switch (bodyItem.type) {
    case bodyItemType.TEXT:
      return <p key={key}>{bodyItem.content}</p>;
    case bodyItemType.LIST:
      return (
        <>
          <SpecialtyHeading>{bodyItem.content.heading}</SpecialtyHeading>
          <ul>
            {bodyItem.content.items.map((i, idx) => (
              <li key={`item-${idx}`}>{i}</li>
            ))}
          </ul>
        </>
      );
    case bodyItemType.SERVICE_PANEL:
      return <ServicePanel key={key} {...bodyItem} />;
    case bodyItemType.H2:
      return <H2 key={key}>{bodyItem.content}</H2>;
    case bodyItemType.DIVIDER:
      return <Divider key={key} />;
    case bodyItemType.TESTIMONIALS:
      return <Testimonials key={key} {...bodyItem} />;
    case bodyItemType.HTML:
      return <bodyItem.content key={key} />;
    case bodyItemType.INSTRUCTION:
      return <Instruction key={key}>{bodyItem.content}</Instruction>;
    case bodyItemType.FAQ:
      return <FAQs key={key} faqs={bodyItem.faqs} />;
    case bodyItemType.CONTACT_US:
      return <ContactUs key={key} />;
    case bodyItemType.SIDE_PANEL_ITEM:
      return <SidePanelItem key={key} {...bodyItem} />;
    default:
      return;
  }
};
