import { bodyItemType } from '../helpers/bodyRenderer';

export const contact = {
  heading: 'Contact',
  body: [
    {
      type: bodyItemType.TEXT,
      content:
        'To request a free consultation or if you have any questions please feel free to contact us using the contact information below:',
    },
    {
      type: bodyItemType.CONTACT_US,
    },
  ],
};
