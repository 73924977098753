export const summarizeText = (text, maxLength) => {
  if (text.length > maxLength) {
    let n = maxLength;
    let lastChar;
    const stringBuilder = [];
    stringBuilder.push(text.substr(0, n));

    while (n < text.length && text.substr(n, 1) !== ' ') {
      stringBuilder.push(text.substr(n, 1));
      n++;
      if (n >= text.length || text.substr(n, 1) === ' ') {
        lastChar = text.substr(n - 1, 1);
      }
    }

    if (lastChar !== '.') {
      stringBuilder.push('...');
    }

    return stringBuilder.join('');
  }

  return text;
};
