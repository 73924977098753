import { bodyItemType } from '../helpers/bodyRenderer';

export const testimonials = {
  heading: 'Testimonials',
  body: [
    {
      type: bodyItemType.TEXT,
      content:
        "Still have your doubts about the benefits of personal training? Don't take our word for it, read what some of our clients have to say about the personal training services offered by Body Best Fitness.",
    },
    { type: bodyItemType.DIVIDER },
    {
      type: bodyItemType.TESTIMONIALS,
      testimonials: [
        {
          author: 'Louise',
          text:
            "I was looking for a personal trainer who understood my desire to lose weight, but who also understood my limitations, but at the same time pushed beyond those boundaries in a fun and enjoyable work out and Best managed all those expectations. I lost 9.5Kgs and dropped two dress sizes. He was great fun to work with, always engaged in 'your' work out. I would go back to Best for the next two dress sizes!",
        },
        {
          author: 'Clive R. Garston',
          text:
            "I have been a regular client of Best since 2007 and have found him to be an excellent personal trainer. Before meeting him I had been a regular gym user and had had various trainers. My workouts are now more effective and Best is a great motivator. He varies the sessions so that they are not boring and adapts to one's personal situation. I cannot recommend him highly enough.",
        },
        {
          author: 'Martha Kemp',
          text:
            'Best trained me during both of my pregnancies, one in 2009 and the second in 2011. He was an excellent trainer who understood my desire to stay fit and healthy whilst being sympathetic to my changing body. The training enabled me to stay strong and toned physically and was a great way to escape the rigours of work!  I would strongly recommend him to women who are expecting.',
        },
      ],
    },
    {
      type: bodyItemType.DIVIDER,
    },
  ],
};
