import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import Logo from '../../images/body-best-fitness-logo.svg';
import { ContactInfo } from '../contact-info';
import { servicesMenu } from './data';
import { SubMenuIcon } from './sub-menu-icon';
import {
  SiteHeader,
  SiteHeaderContent,
  MainNavigation,
  MainNavLinks,
  MainNavLink,
  MainNavigationPattern,
  MainNavSubNavMenu,
  MainNavSubNavMenuLink,
  HamburgerLabel,
  HamburgerBar,
  HamburgerCheckbox,
} from './styles';

export const Header = ({ siteTitle }) => {
  const [isChecked, setIsChecked] = useState(false);

  const onCheckboxChange = () => {
    const newIsChecked = !isChecked;
    setIsChecked(newIsChecked);
    document.body.style.overflow = newIsChecked ? 'hidden' : '';
  };

  const onMenuClick = () => {
    setIsChecked(false);
    document.body.style.overflow = '';
  };

  return (
    <SiteHeader>
      <SiteHeaderContent>
        <ContactInfo />
        <Logo className="logo" alt={siteTitle} />
      </SiteHeaderContent>
      <MainNavigation>
        <MainNavigationPattern>
          <HamburgerCheckbox
            id="hamburger-checkbox"
            type="checkbox"
            checked={isChecked}
            onChange={onCheckboxChange}
          />
          <HamburgerLabel htmlFor="hamburger-checkbox">
            <HamburgerBar />
            <HamburgerBar />
            <HamburgerBar />
          </HamburgerLabel>
          <MainNavLinks onClick={onMenuClick}>
            <li>
              <MainNavLink to="/">HOME</MainNavLink>
            </li>
            <li>
              <MainNavLink to="/services">
                SERVICES <SubMenuIcon />
              </MainNavLink>
              <MainNavSubNavMenu>
                {servicesMenu.map(s => (
                  <li key={`${s.text.replace(' ', '-')}`}>
                    <MainNavSubNavMenuLink to={s.href}>
                      {s.text}
                    </MainNavSubNavMenuLink>
                  </li>
                ))}
              </MainNavSubNavMenu>
            </li>
            <li>
              <MainNavLink to="/testimonials">TESTIMONIALS</MainNavLink>
            </li>
            <li>
              <MainNavLink to="/faqs">FAQ</MainNavLink>
            </li>
            <li>
              <MainNavLink to="/contact">CONTACT</MainNavLink>
            </li>
          </MainNavLinks>
        </MainNavigationPattern>
      </MainNavigation>
    </SiteHeader>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};
