import React from 'react';
import styled, { css } from 'styled-components';
import { diagonalLines, linearGradient } from '../styles/background-images';
import { mainHeadingShadow } from '../styles/text';
import { white } from '../styles/colours';
import { DottedDivider } from '../dotted-divider';
import { mq } from '../styles/media-queries';

export const sharedStyles = css`
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  font-family: 'Dodger';
  font-style: italic;
  font-weight: bold;
  letter-spacing: 2px;
  text-rendering: optimizeLegibility;
  color: ${white};
`;

const H1Wrapper = styled.h1`
  ${sharedStyles}
  margin-bottom: 1.45rem;
  background: ${linearGradient};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.8);
  position: relative;
  font-size: 2.25rem;
  line-height: 1.1;

  ${mq.large} {
    font-size: 1.75rem;
  }
  ${mq.medium} {
    font-size: 1.5rem;
  }
  ${mq.small} {
    font-size: 1.25rem;
  }
`;

const H1Pattern = styled.div`
  background: url("${diagonalLines}");
`;

const H1Text = styled.div`
  margin: 0 auto;
  padding: 18px 40px;
  text-shadow: ${mainHeadingShadow};

  ${mq.small} {
    padding: 14px 1rem;
  }
`;

export const H1 = ({ children }) => (
  <H1Wrapper>
    <H1Pattern>
      <H1Text>{children}</H1Text>
    </H1Pattern>
  </H1Wrapper>
);

export const H2 = styled.h2`
  ${sharedStyles}
  font-style: italic;
  font-size: 1.6rem;
  margin: 18px 0 8px 0;
  font-weight: bold;

  ${mq.large} {
    font-size: 1.5rem;
  }
  ${mq.medium} {
    font-size: 1.25rem;
  }
  ${mq.small} {
    font-size: 1rem;
  }
`;

export const MoreHeadingHeading = styled(H2)`
  margin: 0;
  font-weight: bold;
  text-shadow: ${mainHeadingShadow};
`;

export const MoreHeading = () => (
  <>
    <MoreHeadingHeading>More</MoreHeadingHeading>
    <DottedDivider margin="1rem" />
  </>
);

export { SpecialtyHeading } from './specialty-heading';
