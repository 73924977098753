import styled from 'styled-components';
import { grey } from './styles/colours';

export const DottedDivider = styled.div`
  background-image: linear-gradient(
    to right,
    ${grey} 50%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: top;
  background-size: 14px 4px;
  background-repeat: repeat-x;
  height: 4px;
  margin: ${p => (p.margin ? `${p.margin} 0` : '1.5rem 0')};
`;
