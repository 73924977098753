import styled from 'styled-components';
import { mq } from '../styles/media-queries';
import { SolidDivider } from '../solid-divider';

export const ContentSection = styled.div`
  margin: 0 auto;
  padding: 8px 40px 24px 40px;
  font-size: 1.125rem;
  display: flex;

  ${mq.medium} {
    display: block;
    padding: 8px 32px 24px 32px;
  }
  ${mq.small} {
    padding: 0 1rem 24px 1rem;
  }
`;

export const ContentRight = styled.div`
  min-width: 300px;
  max-width: 300px;
  margin-left: 24px;

  ${mq.medium} {
    min-width: 0;
    max-width: none;
    margin-left: 0;
  }
`;

export const ContentRightDivider = styled(SolidDivider)`
  display: none;

  ${mq.medium} {
    display: block;
  }
`;

export const ServiceIndexContentSection = styled(ContentSection)`
  ${mq.large} {
    display: block;

    ${ContentRight} {
      margin-left: 0;

      ${mq.large} {
        min-width: 0;
        max-width: none;
        margin-left: 0;
      }
    }
  }
`;
