import React from 'react';
import styled, { css } from 'styled-components';
import { blue } from './styles/colours';
import { Link, MoreLink } from './styles/link';
import { SolidDivider } from '.';
import { summarizeText } from '../helpers/summarizeText';
import { ServiceIndexContentSection, ContentSection } from './styles/layout';
import { mq } from './styles/media-queries';

export const SidePanelItemSolidDivider = styled(SolidDivider)`
  height: 3px;
  margin: 1.25rem 0 1rem 0;
`;

const inlineSidePanelContainerStyles = css`
  display: inline-block;
  width: 50%;

  p {
    min-height: 90px;
  }

  &:nth-of-type(odd) {
    padding-left: 0.75rem;
  }

  &:nth-of-type(even) {
    padding-right: 0.75rem;
  }

  &:last-of-type {
    ${SidePanelItemSolidDivider} {
      display: block;
    }
  }
`;

const blockSidePanelContainerStles = css`
  display: block;
  width: auto;
  padding: 0;

  p {
    min-height: 70px;
  }

  &:nth-of-type(odd) {
    padding-left: 0;
    padding-right: 0;
  }

  &:nth-of-type(even) {
    padding-right: 0;
    padding-left: 0;
  }

  &:last-of-type {
    ${SidePanelItemSolidDivider} {
      display: none;
    }
  }
`;

const SidePanelItemContent = styled.div`
  padding-left: 96px;
  padding-bottom: 0.8rem;
  position: relative;
  font-size: 0.9375rem;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
  }
`;

export const SidePanelItemContainer = styled.div`
  margin-bottom: 1rem;
  vertical-align: top;

  &:last-of-type {
    ${SidePanelItemSolidDivider} {
      display: none;
    }
  }

  ${ContentSection} & {
    ${mq.medium} {
      ${inlineSidePanelContainerStyles}

      &:nth-of-type(odd) {
        padding-right: 0.75rem;
        padding-left: 0;
      }

      &:nth-of-type(even) {
        padding-left: 0.75rem;
        padding-right: 0;
      }
    }

    ${mq.small} {
      ${blockSidePanelContainerStles}
    }

    ${SidePanelItemContent} {
      ${mq.medium} {
        font-size: 1rem;
      }
    }
  }

  ${ServiceIndexContentSection} & {
    ${mq.large} {
      ${inlineSidePanelContainerStyles}

      &:nth-of-type(odd) {
        padding-right: 0.75rem;
        padding-left: 0;
      }

      &:nth-of-type(even) {
        padding-left: 0.75rem;
        padding-right: 0;
      }
    }

    ${mq.small} {
      ${blockSidePanelContainerStles}
    }

    ${SidePanelItemContent} {
      ${mq.large} {
        font-size: 18px;
      }
      ${mq.medium} {
        font-size: 1rem;
      }
    }
  }
`;

const SidePanelItemHeading = styled.h3`
  color: ${blue};
  font-style: italic;
  font-size: 1.25rem;
  margin: 0 0 1rem 0;
  text-transform: capitalize;
`;

const SidePanelItemMoreLink = styled(MoreLink)`
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const SidePanelItem = props => {
  const { heading, imageUrl, text, isService } = props;
  const hyphenatedName = heading.toLowerCase().replace(/ /g, '-');
  const url = `${isService ? '/services/' : ''}${hyphenatedName}`;

  return (
    <SidePanelItemContainer>
      <SidePanelItemHeading>
        <Link to={url}>{heading.toLowerCase()}</Link>
      </SidePanelItemHeading>
      <SidePanelItemContent>
        <Link to={url}>
          <img src={imageUrl} alt={heading} />
        </Link>
        <p>{summarizeText(text, 130)}</p>
        <SidePanelItemMoreLink to={url}>Find Out More</SidePanelItemMoreLink>
      </SidePanelItemContent>
      <SidePanelItemSolidDivider />
    </SidePanelItemContainer>
  );
};
